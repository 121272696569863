<template>
    <section class="obras-servicios-lista">
        <titulo-divisor titulo="Calendarios servicios" />
    </section>
</template>

<script>
export default {
    data(){
        return{
            
        }
        
    },
    methods:{

    }

}
</script>

<style>

</style>